<template>
  <div class="base-set">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/pc/index' }">PC网站</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form ref="formRef" :model="pageForm" label-width="120px">
        <el-form-item label="站点名称">
          <el-input v-model="pageForm.site_name" placeholder="请输入站点名称"></el-input>
        </el-form-item>
        <el-form-item label="网站关键字">
          <el-input type="textarea" :rows="2" placeholder="请输入网站关键字" v-model="pageForm.site_keyword"></el-input>
        </el-form-item>
        <el-form-item label="网站描述">
          <el-input type="textarea" :rows="2" placeholder="请输入网站描述" v-model="pageForm.site_desc"></el-input>
        </el-form-item>
        <el-form-item label="网站备案号">
          <el-input v-model="pageForm.beian" placeholder="请输入网站备案号"></el-input>
        </el-form-item>
        <el-form-item label="网站LOGO">
          <upload :url="pageForm.logo" :filed="'logo'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="客服QQ">
          <el-input v-model="pageForm.kfqq" placeholder="请输入客服QQ号码"></el-input>
        </el-form-item>
        <el-form-item label="客服微信">
          <upload :url="pageForm.kfwx" :filed="'kfwx'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="公众号二维码">
          <upload :url="pageForm.ewm" :filed="'ewm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input v-model="pageForm.company_name" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="公司电话">
          <el-input v-model="pageForm.company_phone" placeholder="请输入公司电话"></el-input>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-input v-model="pageForm.company_address" placeholder="请输入公司地址"></el-input>
        </el-form-item>
        <el-form-item label="公司邮箱">
          <el-input v-model="pageForm.company_mail" placeholder="请输入公司邮箱"></el-input>
        </el-form-item>
        <el-form-item label="公司地图坐标">
          <div class="flex-input">
            <el-input v-model="pageForm.company_coord" placeholder="请点击右侧按钮选取坐标"></el-input>
            <coord-picker @configAddress="configAddress"></coord-picker>
          </div>
        </el-form-item>
        <el-form-item label="公司简介">
          <QuillBar v-model="pageForm.company_profile" :contenttext="pageForm.company_profile" @inputChange="itemChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">修改设置</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import CoordPicker from '@/components/common/CoordPicker'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'BaseSet',
  components: {
    Upload,
    CoordPicker,
    QuillBar
  },
  data () {
    return {
      pageForm: {
        site_name: '',
        site_keyword: '',
        site_desc: '',
        beian: '',
        logo: '',
        kfwx: '',
        kfqq: '',
        ewm: '',
        company_name: '',
        company_phone: '',
        company_address: '',
        company_mail: '',
        company_coord: '',
        company_profile: ''
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      const { data: res } = await this.$http.get('pc-base-set')
      if (res.status === 200) {
        this.pageForm = res.data
      }
    },
    async onSubmit () {
      const { data: res } = await this.$http.post('pc-base-set', this.pageForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    cancel () {
      this.$router.push('/admin/apps/pc/index')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    // 坐标选取返回
    configAddress (coordAddress) {
      this.pageForm.company_coord = coordAddress.lnglat
    },
    // 公司简介变化事件
    itemChange (val) {
      this.pageForm.company_profile = val
    }
  }
}
</script>

<style scoped>
.flex-input{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-input .el-button{
  margin-left: 10px;
}
/deep/.ql-container{
  min-height: 200px;
}
</style>
